/*
 * @Author: N0ts
 * @Date: 2023-03-07 16:58:59
 * @Description: config
 * @FilePath: /chat-gpt/src/config/config.ts
 * @Mail：mail@n0ts.top
 */

export default {
    apiBaseUrl: "https://api.n0ts.top",
    key: "sk-cV7vUyry0jLUI4xP610729Fd471d417598815d0bF66771A8",
    data: {
        model: "gpt-3.5-turbo"
    },
    system: "",
    max_token: 4096,
    robot: "chatgpt"
};
